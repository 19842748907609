import { gql } from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  /** Decimal custom scalar type */
  Decimal: any;
  JSON: any;
  Upload: any;
};

export type Account = {
  __typename?: 'Account';
  account: Scalars['String'];
  active: Scalars['Boolean'];
  created_at: CustomDate;
  credit_configs?: Maybe<CreditConfigs>;
  detail?: Maybe<AccountDetail>;
  erp_configs: ErpConfigs;
  feature?: Maybe<Feature>;
  group?: Maybe<AccountGroup>;
  id: Scalars['Int'];
  layout?: Maybe<AccountLayout>;
  mdopay_configs?: Maybe<MdoPayConfigs>;
  meta_configs?: Maybe<Scalars['JSON']>;
  meta_labels?: Maybe<Scalars['JSON']>;
  password_manager: Scalars['String'];
  payment: AccountInfoPayment;
  sync_error?: Maybe<Scalars['String']>;
  updated_at: CustomDate;
};

export type AccountDetail = {
  __typename?: 'AccountDetail';
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  cnpj: Scalars['String'];
  company_name: Scalars['String'];
  country?: Maybe<Scalars['String']>;
  cpf_rep?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  email_adm?: Maybe<Scalars['String']>;
  email_rep?: Maybe<Scalars['String']>;
  ie?: Maybe<Scalars['String']>;
  name_rep?: Maybe<Scalars['String']>;
  phone_number1?: Maybe<Scalars['String']>;
  phone_number2?: Maybe<Scalars['String']>;
  razao_social?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zip_code?: Maybe<Scalars['String']>;
};

export type AccountEdgesResponse = {
  __typename?: 'AccountEdgesResponse';
  edges: Array<Account>;
  pagination?: Maybe<Pagination>;
};

export enum AccountErpEnum {
  None = 'NONE',
  Saas = 'SAAS'
}

export type AccountGroup = {
  __typename?: 'AccountGroup';
  account: Scalars['String'];
  active: Scalars['Boolean'];
  feature?: Maybe<Feature>;
  id: Scalars['Int'];
};

export type AccountInfo = {
  __typename?: 'AccountInfo';
  account: Scalars['String'];
  active: Scalars['Boolean'];
  created: CustomDate;
  detail?: Maybe<AccountDetail>;
  feature?: Maybe<Feature>;
  group?: Maybe<AccountGroup>;
  id: Scalars['Int'];
  modified: CustomDate;
};

export type AccountInfoPayment = {
  __typename?: 'AccountInfoPayment';
  enable_v2: Scalars['Boolean'];
  payment_plans?: Maybe<Array<PaymentPlan>>;
};

export type AccountKcUser = {
  __typename?: 'AccountKcUser';
  account: Scalars['Int'];
  created_at: CustomDate;
  id: Scalars['ID'];
  kc_sub: Scalars['String'];
  roles: Array<RoleEnum>;
  updated_at: CustomDate;
};

export type AccountLayout = {
  __typename?: 'AccountLayout';
  background_ref?: Maybe<Scalars['String']>;
  background_src?: Maybe<Scalars['String']>;
  layout_ref?: Maybe<Scalars['String']>;
  logo_ref?: Maybe<Scalars['String']>;
  logo_src?: Maybe<Scalars['String']>;
};

export type AccountResponse = {
  __typename?: 'AccountResponse';
  data: Account;
  message: Scalars['String'];
};

export type AddPaymentInput = {
  amount: Scalars['Float'];
  brand: Scalars['String'];
  /**  Motivo da falha do pagamento, apenas quando status=Failed  */
  failure_reason?: InputMaybe<Scalars['String']>;
  installments: Scalars['Int'];
  /**  Descrição da operação, ex: Parcelado Loja  */
  label_name: Scalars['String'];
  nsu: Scalars['String'];
  nsu_external: Scalars['String'];
  nsu_rede: Scalars['String'];
  /**  Numero do cartão mascarado  */
  pan_masked: Scalars['String'];
  payment_method: PaymentMethodEnum;
  ref_sdk_id?: InputMaybe<Scalars['Int']>;
  /**  String JSON livre para salvar o payload da requisição na SDK  */
  request_payload: Scalars['String'];
  /**  String JSON livre para salvar o payload da resposta da SDK  */
  response_payload: Scalars['String'];
  status: PaymentStatusEnum;
  terminal?: InputMaybe<Scalars['String']>;
};

export enum AlertIconEnum {
  Error = 'Error',
  Info = 'Info',
  Success = 'Success',
  Warning = 'Warning'
}

export type CancelPaymentInput = {
  cancel_reason?: InputMaybe<Scalars['String']>;
  nsu_rede: Scalars['String'];
  ref_sdk_id?: InputMaybe<Scalars['Int']>;
  request_payload: Scalars['JSON'];
  response_payload: Scalars['JSON'];
  terminal?: InputMaybe<Scalars['String']>;
};

export type Capacitacao = {
  __typename?: 'Capacitacao';
  app: CapacitacaoAppEnum;
  ativo: Scalars['Boolean'];
  createdAt: CustomDate;
  descricao?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  status: CapacitacaoStatus;
  titulo: Scalars['String'];
  topicos: Array<Topico>;
  updatedAt: CustomDate;
};

export enum CapacitacaoAppEnum {
  Ivision = 'IVISION',
  Visiolens = 'VISIOLENS'
}

export type CapacitacaoResponse = {
  __typename?: 'CapacitacaoResponse';
  data: Capacitacao;
  message: Scalars['String'];
};

export type CapacitacaoStatus = {
  __typename?: 'CapacitacaoStatus';
  pc_progresso: Scalars['Int'];
  qt_finalizados: Scalars['Int'];
  qt_total: Scalars['Int'];
};

export type CartItem = {
  __typename?: 'CartItem';
  code: Scalars['String'];
  display_name?: Maybe<Scalars['String']>;
  eye: EyeTypeEnum;
  name: Scalars['String'];
  price: Scalars['Float'];
  quantity: Scalars['Int'];
  type: ProductTypeEnum;
};

export type CartItemInput = {
  code: Scalars['String'];
  display_name?: InputMaybe<Scalars['String']>;
  eye: EyeTypeEnum;
  name: Scalars['String'];
  price: Scalars['Float'];
  quantity: Scalars['Int'];
  type: ProductTypeEnum;
};

export type CheckCampaignsClient = {
  __typename?: 'CheckCampaignsClient';
  document: Scalars['String'];
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  purchaseCount: Scalars['Int'];
  timeMonths: Scalars['Int'];
};

export type CheckCampaignsCoupon = {
  __typename?: 'CheckCampaignsCoupon';
  actions: Array<PromoActionCheck>;
  code: Scalars['String'];
  description: Scalars['String'];
  id: Scalars['ID'];
  validUntil: Scalars['Date'];
};

export type CheckCampaignsInput = {
  couponCode?: InputMaybe<Scalars['String']>;
  cpf?: InputMaybe<Scalars['String']>;
  debug?: InputMaybe<Scalars['Boolean']>;
};

export type CheckCampaignsResult = {
  __typename?: 'CheckCampaignsResult';
  allowCampaigns: Array<Scalars['ID']>;
  client?: Maybe<CheckCampaignsClient>;
  coupon?: Maybe<CheckCampaignsCoupon>;
  debug?: Maybe<Scalars['JSON']>;
  usedCampaigns: Array<CheckCampaignsUsed>;
};

export type CheckCampaignsUsed = {
  __typename?: 'CheckCampaignsUsed';
  campaign: Scalars['ID'];
  times: Scalars['Int'];
};

export type CheckoutTransactionResponse = {
  __typename?: 'CheckoutTransactionResponse';
  /**  URL para download da imagem BMP da NFC-e  */
  downloadBmp?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  transaction: Transaction;
};

export type Client = {
  __typename?: 'Client';
  account: Scalars['Int'];
  ativo: Scalars['Boolean'];
  bairro?: Maybe<Scalars['String']>;
  celular?: Maybe<Scalars['String']>;
  cep?: Maybe<Scalars['String']>;
  cidade?: Maybe<Scalars['String']>;
  codigo_ws?: Maybe<Scalars['String']>;
  complemento?: Maybe<Scalars['String']>;
  cpf?: Maybe<Scalars['String']>;
  created_at: CustomDate;
  data_cadastro?: Maybe<CustomDate>;
  data_nascimento?: Maybe<CustomDate>;
  email?: Maybe<Scalars['String']>;
  estrangeiro?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  nome?: Maybe<Scalars['String']>;
  numero?: Maybe<Scalars['String']>;
  observacao?: Maybe<Scalars['String']>;
  pais?: Maybe<Scalars['String']>;
  rg?: Maybe<Scalars['String']>;
  rua?: Maybe<Scalars['String']>;
  sexo?: Maybe<ClientSexoEnum>;
  telefone?: Maybe<Scalars['String']>;
  uf?: Maybe<Scalars['String']>;
  updated_at: CustomDate;
};

export enum ClientSexoEnum {
  F = 'F',
  M = 'M'
}

export type ConditionAccountInput = {
  active?: InputMaybe<Scalars['Boolean']>;
};

export type ConditionDateInput = {
  eq?: InputMaybe<Scalars['Date']>;
  gt?: InputMaybe<Scalars['Date']>;
  gte?: InputMaybe<Scalars['Date']>;
  lt?: InputMaybe<Scalars['Date']>;
  lte?: InputMaybe<Scalars['Date']>;
  ne?: InputMaybe<Scalars['Date']>;
};

export type ConditionFloatInput = {
  eq?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  ne?: InputMaybe<Scalars['Float']>;
};

export type ConditionIntInput = {
  eq?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  ne?: InputMaybe<Scalars['Int']>;
};

export type ConditionOrderInput = {
  pendingPayment?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<OrderStatusEnum>;
};

export type ConditionProductInput = {
  code?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<ProductTypeEnum>;
};

export type ConditionTransactionInput = {
  status?: InputMaybe<TransactionStatusEnum>;
};

export type ConditionUserInput = {
  account?: InputMaybe<Scalars['Int']>;
};

export type ConfigErpInput = {
  chave?: InputMaybe<Scalars['String']>;
  empresa?: InputMaybe<Scalars['String']>;
  erp: AccountErpEnum;
  portal?: InputMaybe<Scalars['String']>;
  sandbox?: InputMaybe<Scalars['Boolean']>;
  tabela_preco?: InputMaybe<Scalars['String']>;
};

export type CopyTablesInput = {
  account: Scalars['Int'];
  ids: Array<Scalars['Int']>;
  labels?: InputMaybe<Array<Scalars['String']>>;
};

export type CountProduct = {
  __typename?: 'CountProduct';
  percent_price_synchronized: Scalars['Float'];
  quantity_disabled: Scalars['Int'];
  quantity_enable: Scalars['Int'];
  quantity_total: Scalars['Int'];
  quantity_with_price: Scalars['Int'];
  text: Scalars['String'];
  type: Scalars['String'];
};

export type CreateAccountDetailInput = {
  address?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  cnpj: Scalars['String'];
  company_name: Scalars['String'];
  country?: InputMaybe<Scalars['String']>;
  cpf_rep?: InputMaybe<Scalars['String']>;
  district?: InputMaybe<Scalars['String']>;
  email_adm?: InputMaybe<Scalars['String']>;
  email_rep?: InputMaybe<Scalars['String']>;
  ie?: InputMaybe<Scalars['String']>;
  name_rep?: InputMaybe<Scalars['String']>;
  phone_number1?: InputMaybe<Scalars['String']>;
  phone_number2?: InputMaybe<Scalars['String']>;
  razao_social?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  zip_code?: InputMaybe<Scalars['String']>;
};

export type CreateAccountInput = {
  account: Scalars['String'];
  detail: CreateAccountDetailInput;
  feature_id: Scalars['Int'];
  group_id?: InputMaybe<Scalars['Int']>;
  meta_configs?: InputMaybe<Scalars['JSON']>;
  meta_labels?: InputMaybe<Scalars['JSON']>;
  password_manager?: InputMaybe<Scalars['String']>;
};

export type CreateCapacitacaoInput = {
  app: CapacitacaoAppEnum;
  ativo?: InputMaybe<Scalars['Boolean']>;
  descricao?: InputMaybe<Scalars['String']>;
  titulo: Scalars['String'];
};

export type CreateNoteInput = {
  account: Scalars['Int'];
  content: Scalars['String'];
  type: NoteTypeEnum;
};

export type CreateTablesInput = {
  account: Scalars['Int'];
  labels?: InputMaybe<Array<Scalars['String']>>;
  origin?: InputMaybe<Scalars['String']>;
  tables: Array<TableUpload>;
};

export type CreateTransactionInput = {
  /**  Valor total da transação  */
  amount: Scalars['Float'];
  carts?: InputMaybe<Array<TransactionCartInput>>;
  /**  Informações do cliente  */
  client?: InputMaybe<TransactionClientInput>;
  /**  (R$) Valor do desconto da transação  */
  discount: Scalars['Float'];
  /**  Tipo do fluxo de pagamento  */
  flow: TransactionFlowEnum;
  /**
   * Número de parcelas da transação
   *
   * Use o mesmo numero de parcelas simulado no Visiolnes!
   *
   * - 0 para debito
   * - 1 para pagamento à vista
   * - 2+ para parcelado
   */
  max_installments: Scalars['Int'];
  /**  Código do pedido visiolens  */
  order?: InputMaybe<Scalars['ID']>;
};

export type CreateUserInput = {
  account?: InputMaybe<Scalars['Int']>;
  login: Scalars['String'];
  password: Scalars['String'];
  role: RoleEnum;
};

export type CreditAuthProvider = {
  __typename?: 'CreditAuthProvider';
  createdAt: CustomDate;
  /** Chave/username de acesso do provedor */
  key: Scalars['String'];
  provider: CreditMdoProviderEnum;
  /** Senha de acesso do provedor */
  secret: Scalars['String'];
  updatedAt: CustomDate;
};

export type CreditAuthProviderInput = {
  /** Chave/username de acesso do provedor */
  key: Scalars['String'];
  /** Senha de acesso do provedor */
  secret: Scalars['String'];
};

export type CreditConfigs = {
  __typename?: 'CreditConfigs';
  account: Scalars['Int'];
  auth_providers: Array<CreditAuthProvider>;
  createdAt: CustomDate;
  provider: CreditMdoProviderEnum;
  table?: Maybe<CreditInterestTable>;
  updatedAt: CustomDate;
};

export type CreditInterestTable = {
  __typename?: 'CreditInterestTable';
  code: Scalars['String'];
  description: Scalars['String'];
  /** Quando true, habilita o simulador de crediario no APP Visiolens */
  enable_simulator: Scalars['Boolean'];
  fees?: Maybe<Array<Scalars['Float']>>;
  provider: CreditMdoProviderEnum;
};

export enum CreditMdoProviderEnum {
  /** Simulação de crediario */
  DemoCred = 'DemoCred',
  /** Crediario do operador MDO, com possibilidades de varios provedores */
  MultiCred = 'MultiCred',
  /** Crediario desativado */
  None = 'None',
  /** Crediario proprio da loja */
  OwnCred = 'OwnCred'
}

export type CustomDate = {
  __typename?: 'CustomDate';
  /** Formats a string to the ISO8601 standard. */
  iso?: Maybe<Scalars['String']>;
  /** String matches the RFC 2822 Date time format */
  string?: Maybe<Scalars['String']>;
  /** Integer value representing the number of milliseconds since the Unix Epoch (Jan 1 1970 12AM UTC). */
  timestamp?: Maybe<Scalars['Float']>;
  /** This function returns the real offset from UTC, not the reverse offset */
  timezone?: Maybe<Scalars['String']>;
  /**
   * If you know the format of an input string, you can use that to parse a date.
   * Read more: [momentjs format date](https://momentjs.com/docs/#/parsing/string-format)
   */
  transform?: Maybe<Scalars['String']>;
  /** Unix timestamp (seconds since the Unix Epoch) */
  unix?: Maybe<Scalars['Float']>;
  /** UTC time instead of local time */
  utc?: Maybe<Scalars['String']>;
};


export type CustomDateTimezoneArgs = {
  offset: Scalars['String'];
};


export type CustomDateTransformArgs = {
  format: Scalars['String'];
};

export type CustomNumber = {
  __typename?: 'CustomNumber';
  currency?: Maybe<Scalars['String']>;
  fixed?: Maybe<Scalars['String']>;
  float?: Maybe<Scalars['Float']>;
  format?: Maybe<Scalars['String']>;
  int?: Maybe<Scalars['Int']>;
  string?: Maybe<Scalars['String']>;
};


export type CustomNumberCurrencyArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  digits?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
};


export type CustomNumberFixedArgs = {
  digits: Scalars['Int'];
};


export type CustomNumberFormatArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  digits?: InputMaybe<Scalars['Int']>;
};

export type DevCreateOrderInput = {
  carts: Array<CartItemInput>;
  clientDocument?: InputMaybe<Scalars['String']>;
  clientName?: InputMaybe<Scalars['String']>;
  clientPhone?: InputMaybe<Scalars['String']>;
  discount: Scalars['Float'];
  payments: Array<OrderPaymentInput>;
};

export type Doctor = {
  __typename?: 'Doctor';
  account: Scalars['Int'];
  code?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  created_at: CustomDate;
  crm: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updated_at: CustomDate;
};

export type ErpConfigs = {
  __typename?: 'ErpConfigs';
  /**
   * "
   * Mesmo: linx_cnpj
   */
  chave?: Maybe<Scalars['String']>;
  empresa?: Maybe<Scalars['String']>;
  erp: AccountErpEnum;
  portal?: Maybe<Scalars['String']>;
  sandbox: Scalars['Boolean'];
  tabela_preco?: Maybe<Scalars['String']>;
};

export enum EyeTypeEnum {
  Both = 'Both',
  Left = 'Left',
  Right = 'Right'
}

export type Feature = {
  __typename?: 'Feature';
  created_at: CustomDate;
  data: Array<FeatureData>;
  id: Scalars['Int'];
  name: Scalars['String'];
  updated_at: CustomDate;
};

export type FeatureData = {
  __typename?: 'FeatureData';
  feature: Scalars['String'];
  quote?: Maybe<Scalars['Int']>;
};

export enum FilterScopeEnum {
  OnlyActive = 'OnlyActive',
  OnlyTrashed = 'OnlyTrashed',
  WithTrashed = 'WithTrashed'
}

export type GetPdfResponse = {
  __typename?: 'GetPDFResponse';
  bmp: Scalars['String'];
  pdf: Scalars['String'];
};

export type IPromoAction = {
  description: Scalars['String'];
  discountPercent?: Maybe<Scalars['Float']>;
  discountProducts?: Maybe<Array<Scalars['String']>>;
  giftProducts?: Maybe<Array<Scalars['String']>>;
  imageUrl?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  type: PromoActionTypeEnum;
  videoUrl?: Maybe<Scalars['String']>;
};

export type InfoApi = {
  __typename?: 'InfoAPI';
  commit: Scalars['String'];
  date?: Maybe<Scalars['String']>;
  environment: Scalars['String'];
  image: Scalars['String'];
  name: Scalars['String'];
  shortCommit: Scalars['String'];
  version: Scalars['String'];
};

export type Layout = {
  __typename?: 'Layout';
  background_is_custom: Scalars['Boolean'];
  background_src?: Maybe<Scalars['String']>;
  logo_is_custom: Scalars['Boolean'];
  logo_src?: Maybe<Scalars['String']>;
  template_ref?: Maybe<Scalars['String']>;
};

export type LayoutInput = {
  background?: InputMaybe<Scalars['Upload']>;
  logo?: InputMaybe<Scalars['Upload']>;
  template_ref?: InputMaybe<Scalars['String']>;
};

export type LayoutResponse = {
  __typename?: 'LayoutResponse';
  data: Layout;
  message: Scalars['String'];
};

export type LoadCepExternalApiResponse = {
  __typename?: 'LoadCepExternalApiResponse';
  cep?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
};

export type LoadCnpjExternalApiResponse = {
  __typename?: 'LoadCnpjExternalApiResponse';
  account_detail?: Maybe<AccountDetail>;
  warning?: Maybe<Scalars['String']>;
};

export type LoginInput = {
  origin?: InputMaybe<LoginOriginEnum>;
  password: Scalars['String'];
  username: Scalars['String'];
};

export type LoginKcInput = {
  password: Scalars['String'];
  secret: Scalars['String'];
  username: Scalars['String'];
};

export enum LoginOriginEnum {
  Default = 'Default',
  Ivision = 'IVISION',
  IvisionAppOs = 'IVISION_APP_OS',
  MdovisionApp = 'MDOVISION_APP',
  PanelV2 = 'PanelV2'
}

export type LoginResponse = {
  __typename?: 'LoginResponse';
  /** JWT access API */
  access_token: Scalars['String'];
  /**
   * Mesmo tokenAPI concatenando Bearer
   * Forma pratica de copiar token para colar no header do playground
   */
  authorization: Scalars['String'];
  /** Tempo para expirar o token */
  expires_in: Scalars['Float'];
};

export type MdoPayConfigs = {
  __typename?: 'MdoPayConfigs';
  createdAt: CustomDate;
  /**  Habilia os fluxos de pagamento, obs a KEY é o proprio enum  */
  flows: MdoPayFlowConfig;
  gateway?: Maybe<MdoPayGatewayConfig>;
  limits: MdoPayLimitsConfig;
  logo_pos: Scalars['String'];
  payment_plans: Array<PaymentPlan>;
  updatedAt: CustomDate;
};

export type MdoPayFlowConfig = {
  __typename?: 'MdoPayFlowConfig';
  DAV: Scalars['Boolean'];
  DirectSale: Scalars['Boolean'];
  ExpressSale: Scalars['Boolean'];
  OrderList: Scalars['Boolean'];
  QrCode: Scalars['Boolean'];
};

export type MdoPayFlowConfigInput = {
  DAV?: InputMaybe<Scalars['Boolean']>;
  DirectSale?: InputMaybe<Scalars['Boolean']>;
  ExpressSale?: InputMaybe<Scalars['Boolean']>;
  OrderList?: InputMaybe<Scalars['Boolean']>;
  QrCode?: InputMaybe<Scalars['Boolean']>;
};

export type MdoPayGatewayConfig = {
  __typename?: 'MdoPayGatewayConfig';
  document: Scalars['String'];
  route: Scalars['String'];
  sandbox: Scalars['Boolean'];
  updatedAt: CustomDate;
};

export type MdoPayGatewayInput = {
  document: Scalars['String'];
  route: Scalars['String'];
  sandbox?: InputMaybe<Scalars['Boolean']>;
};

export type MdoPayLimitsConfig = {
  __typename?: 'MdoPayLimitsConfig';
  /**
   * Valor total mínimo para pagamento com 2 cartões
   * - Obs: em caso de valor ZERO, significa que função esta habilitada
   */
  min_2_cards: Scalars['Float'];
  /**
   * Valor total mínimo para pagamento com 3 cartões
   * - Obs: em caso de valor ZERO, significa que função esta habilitada
   */
  min_3_cards: Scalars['Float'];
  /**
   * Data de atualização das configurações
   * - Obs: Caso esteja NULL, significa que esta usando valores padrao
   */
  updatedAt?: Maybe<CustomDate>;
};

export type MdoPayLimitsInput = {
  /**
   * Valor total mínimo para pagamento com 2 cartões
   * - Informe ZERO para desabilitar
   * - Inform NULL para usar valor padrão
   */
  min_2_cards?: InputMaybe<Scalars['Float']>;
  /**
   * Valor total mínimo para pagamento com 3 cartões
   * - Informe ZERO para desabilitar
   * - Inform NULL para usar valor padrão
   */
  min_3_cards?: InputMaybe<Scalars['Float']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /**  Registra um pagamento a uma transação sendo ela sucesso ou falha  */
  addPayment: PaymentResponse;
  /**  Quando precisa estornar um pagamento, ja cobrado no cartao do cliente  */
  cancelPayment: PaymentResponse;
  /**  Finaliza a transação  */
  checkoutTransaction: CheckoutTransactionResponse;
  copyTables: TableResponse;
  createAccount: AccountResponse;
  createCapacitacao: CapacitacaoResponse;
  createNote: NoteResponse;
  createTables: TableResponse;
  /**  Inicia uma nova transação  */
  createTransaction: TransactionResponse;
  createUser: UserResponse;
  /** @deprecated Demo purposes only */
  devCreateOrder: Order;
  login: LoginResponse;
  loginKC: LoginResponse;
  ping: SimpleResponse;
  presignedUploadCapacitacao: UploadSignedObject;
  removeCapacitacao: CapacitacaoResponse;
  removeLayout: SimpleResponse;
  removeNote: NoteResponse;
  removeTables: TableResponse;
  removeTopicoCapacitacao: CapacitacaoResponse;
  removeUser: UserResponse;
  removeVideoCapacitacao: CapacitacaoResponse;
  /**  Quando precisa estornar um pagamento no outro dia, o processo deve ser feito manualmente  */
  requestCancelPayment: PaymentResponse;
  resetMdoPayConfigs: SimpleResponse;
  resetMdoPayConfigsFromAdmin: SimpleResponse;
  setActiveAccount: AccountResponse;
  setBlockAccount: AccountResponse;
  syncProducts: SimpleResponse;
  updateAccount: AccountResponse;
  updateCapacitacao: CapacitacaoResponse;
  updateConfigErp: SimpleResponse;
  /**  Atualiza dados da transação de forma parcial  */
  updateTransaction: TransactionResponse;
  updateUser: UserResponse;
  upsertAccountKcUser: SimpleResponse;
  upsertCreditConfigs: UpsertCreditConfigsResponse;
  upsertLayout: LayoutResponse;
  upsertMdoPayConfigs: UpsertMdoPayConfigsResponse;
  upsertTopicoCapacitacao: CapacitacaoResponse;
  upsertVideoCapacitacao: CapacitacaoResponse;
};


export type MutationAddPaymentArgs = {
  input: AddPaymentInput;
  transaction: Scalars['ID'];
};


export type MutationCancelPaymentArgs = {
  input: CancelPaymentInput;
};


export type MutationCheckoutTransactionArgs = {
  transaction: Scalars['ID'];
};


export type MutationCopyTablesArgs = {
  input: CopyTablesInput;
};


export type MutationCreateAccountArgs = {
  input: CreateAccountInput;
};


export type MutationCreateCapacitacaoArgs = {
  input: CreateCapacitacaoInput;
};


export type MutationCreateNoteArgs = {
  input: CreateNoteInput;
};


export type MutationCreateTablesArgs = {
  input: CreateTablesInput;
};


export type MutationCreateTransactionArgs = {
  input: CreateTransactionInput;
};


export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


export type MutationDevCreateOrderArgs = {
  input: DevCreateOrderInput;
};


export type MutationLoginArgs = {
  input: LoginInput;
};


export type MutationLoginKcArgs = {
  input: LoginKcInput;
};


export type MutationPingArgs = {
  message?: InputMaybe<Scalars['String']>;
};


export type MutationPresignedUploadCapacitacaoArgs = {
  capacitacao: Scalars['ID'];
  file: PresignedFileInput;
  topico: Scalars['ID'];
};


export type MutationRemoveCapacitacaoArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveLayoutArgs = {
  ref: Scalars['String'];
};


export type MutationRemoveNoteArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveTablesArgs = {
  ids: Array<Scalars['Int']>;
};


export type MutationRemoveTopicoCapacitacaoArgs = {
  capacitacao: Scalars['ID'];
  topico: Scalars['ID'];
};


export type MutationRemoveUserArgs = {
  id: Scalars['Int'];
};


export type MutationRemoveVideoCapacitacaoArgs = {
  capacitacao: Scalars['ID'];
  topico: Scalars['ID'];
  video: Scalars['ID'];
};


export type MutationRequestCancelPaymentArgs = {
  input: RequestCancelPaymentInput;
};


export type MutationResetMdoPayConfigsArgs = {
  password: Scalars['String'];
};


export type MutationResetMdoPayConfigsFromAdminArgs = {
  account: Scalars['Int'];
};


export type MutationSetActiveAccountArgs = {
  active: Scalars['Boolean'];
  id: Scalars['Int'];
  message: Scalars['String'];
};


export type MutationSetBlockAccountArgs = {
  block: Scalars['Boolean'];
  id: Scalars['Int'];
  message: Scalars['String'];
};


export type MutationSyncProductsArgs = {
  account: Scalars['Int'];
  reset: Scalars['Boolean'];
};


export type MutationUpdateAccountArgs = {
  id: Scalars['Int'];
  input: UpdateAccountInput;
};


export type MutationUpdateCapacitacaoArgs = {
  input: UpdateCapacitacaoInput;
};


export type MutationUpdateConfigErpArgs = {
  account: Scalars['Int'];
  config: ConfigErpInput;
};


export type MutationUpdateTransactionArgs = {
  id: Scalars['ID'];
  input: UpdateTransactionInput;
};


export type MutationUpdateUserArgs = {
  id: Scalars['Int'];
  input: UpdateUserInput;
};


export type MutationUpsertAccountKcUserArgs = {
  input: UpsertAccountKcUserInput;
};


export type MutationUpsertCreditConfigsArgs = {
  input: UpsertCreditConfigsInput;
};


export type MutationUpsertLayoutArgs = {
  input: LayoutInput;
};


export type MutationUpsertMdoPayConfigsArgs = {
  input: UpsertMdoPayConfigsInput;
};


export type MutationUpsertTopicoCapacitacaoArgs = {
  input: UpsertTopicoCapacitacaoInput;
};


export type MutationUpsertVideoCapacitacaoArgs = {
  input: UpsertVideoCapacitacaoInput;
};

export type Note = {
  __typename?: 'Note';
  content: Scalars['String'];
  created_at: CustomDate;
  id: Scalars['ID'];
  type: NoteTypeEnum;
  user?: Maybe<UserInfo>;
};

export type NoteResponse = {
  __typename?: 'NoteResponse';
  data: Note;
  message: Scalars['String'];
};

export enum NoteTypeEnum {
  Block = 'Block',
  Comment = 'Comment',
  Danger = 'Danger',
  Fixed = 'Fixed',
  Info = 'Info',
  System = 'System',
  Warning = 'Warning'
}

export type Order = {
  __typename?: 'Order';
  account?: Maybe<AccountInfo>;
  carts: Array<CartItem>;
  client: OrderClient;
  created_at: CustomDate;
  id: Scalars['ID'];
  issue_date?: Maybe<CustomDate>;
  mdopay_code: Scalars['String'];
  /**  Número de parcelas utlizado na simulação dentro do visiolens  */
  mdopay_installments?: Maybe<Scalars['Int']>;
  mdopay_qrcode: Scalars['String'];
  /**  ID da transacao de pagamento no mdopay  */
  mdopay_transaction?: Maybe<Scalars['ID']>;
  /**  valor referente order.fechamento.tipo  */
  mdopay_type?: Maybe<Scalars['Int']>;
  os: OrderOs;
  patient?: Maybe<OrderPatient>;
  payments: Array<OrderPayment>;
  seller: Scalars['String'];
  status: OrderStatusEnum;
  summary: OrderSummary;
  updated_at: CustomDate;
  user?: Maybe<UserInfo>;
};

export type OrderClient = {
  __typename?: 'OrderClient';
  document: Scalars['String'];
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
};

export type OrderEdgesResponse = {
  __typename?: 'OrderEdgesResponse';
  edges: Array<Order>;
};

export type OrderOs = {
  __typename?: 'OrderOs';
  erp?: Maybe<Scalars['String']>;
  full: Scalars['String'];
  sequence: Scalars['Int'];
  version: Scalars['Int'];
};

export type OrderPatient = {
  __typename?: 'OrderPatient';
  date_of_birth?: Maybe<CustomDate>;
  name: Scalars['String'];
  observation?: Maybe<Scalars['String']>;
  relationship?: Maybe<Scalars['String']>;
};

export type OrderPayment = {
  __typename?: 'OrderPayment';
  brand?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  installments: Scalars['Int'];
  is_mdopay?: Maybe<Scalars['Boolean']>;
  operation: Scalars['String'];
  total_amount: Scalars['Float'];
  type: PaymentTypeEnum;
};

export type OrderPaymentInput = {
  brand?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  installments: Scalars['Int'];
  operation: Scalars['String'];
  total_amount: Scalars['Float'];
  type: PaymentTypeEnum;
};

export enum OrderStatusEnum {
  Budget = 'Budget',
  Sale = 'Sale'
}

export type OrderSummary = {
  __typename?: 'OrderSummary';
  /**  Representa o valor já pago  */
  amount_paid: Scalars['Float'];
  /**  Representa o desconto aplicado ao pedido  */
  discount: Scalars['Float'];
  /**  Representa o valor total após aplicar o desconto  */
  discounted_amount: Scalars['Float'];
  /**  Representa o valor restante a ser pago  */
  remaining_amount: Scalars['Float'];
  /**  Representa o valor total dos produtos no pedido  */
  total_products: Scalars['Float'];
};

export type Pagination = {
  __typename?: 'Pagination';
  last_page: Scalars['Int'];
  page: Scalars['Int'];
  per_page: Scalars['Int'];
  total: Scalars['Int'];
};

export type Payment = {
  __typename?: 'Payment';
  amount: Scalars['Float'];
  brand: Scalars['String'];
  /**  Informações do cancelamento do pagamento, epenas quando status=Cancelled  */
  cancel?: Maybe<PaymentCancel>;
  created_at: CustomDate;
  /**  Motivo da falha do pagamento, apenas quando status=Failed  */
  failure_reason?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  installments: Scalars['Int'];
  /**  Descrição da operação, ex: Parcelado Loja  */
  label_name: Scalars['String'];
  nsu: Scalars['String'];
  nsu_external: Scalars['String'];
  nsu_rede: Scalars['String'];
  /**  Numero do cartão mascarado  */
  pan_masked: Scalars['String'];
  payment_method: PaymentMethodEnum;
  ref_sdk_id?: Maybe<Scalars['Int']>;
  /**  JSON livre para salvar o payload da requisição na SDK  */
  request_payload: Scalars['JSON'];
  /**  JSON livre para salvar o payload da resposta da SDK  */
  response_payload: Scalars['JSON'];
  status: PaymentStatusEnum;
  terminal?: Maybe<Scalars['String']>;
};

export type PaymentBrand = {
  __typename?: 'PaymentBrand';
  credito?: Maybe<Scalars['Boolean']>;
  debito?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  label: Scalars['String'];
  parcelas?: Maybe<Array<Scalars['Int']>>;
};

export type PaymentCancel = {
  __typename?: 'PaymentCancel';
  cancel_reason?: Maybe<Scalars['String']>;
  created_at: CustomDate;
  nsu_rede: Scalars['String'];
  ref_sdk_id?: Maybe<Scalars['Int']>;
  request_payload: Scalars['JSON'];
  response_payload: Scalars['JSON'];
  terminal?: Maybe<Scalars['String']>;
};

export enum PaymentMethodEnum {
  Cash = 'Cash',
  Credit = 'Credit',
  Debit = 'Debit'
}

export type PaymentPlan = {
  __typename?: 'PaymentPlan';
  antecipacao?: Maybe<Scalars['Boolean']>;
  bandeiras: Array<PaymentBrand>;
  credito: Scalars['Boolean'];
  debito: Scalars['Boolean'];
  juros?: Maybe<Array<Scalars['Float']>>;
  nome: Scalars['String'];
  taxa_debito?: Maybe<Scalars['Float']>;
  tipo: PaymentTypeEnum;
};

export type PaymentResponse = {
  __typename?: 'PaymentResponse';
  current_payment: Payment;
  message: Scalars['String'];
  transaction: Transaction;
};

export enum PaymentStatusEnum {
  /**  Pagamento estornado  */
  Cancelled = 'Cancelled',
  /**  Falha ao processar pagamento, utilizado em situações de erro na operação ou quando a bandeira nega pagamento  */
  Failed = 'Failed',
  /**  Pagamento confirmado  */
  Paid = 'Paid'
}

export enum PaymentTypeEnum {
  Boleto = 'BOLETO',
  Brl = 'BRL',
  Cartao = 'CARTAO',
  Cashback = 'CASHBACK',
  Cheque = 'CHEQUE',
  Convenio = 'CONVENIO',
  Crediario = 'CREDIARIO',
  CrediarioProprio = 'CREDIARIO_PROPRIO',
  Deposito = 'DEPOSITO',
  MdoPay = 'MDO_PAY',
  Ouro = 'OURO',
  Pix = 'PIX',
  ValeFuncionario = 'VALE_FUNCIONARIO',
  ValePresente = 'VALE_PRESENTE',
  ValeTroca = 'VALE_TROCA'
}

export type PerfilCurso = {
  __typename?: 'PerfilCurso';
  ano_nascimento: Scalars['Int'];
  conta: Scalars['Int'];
  createdAt: CustomDate;
  disabledAt?: Maybe<CustomDate>;
  id: Scalars['ID'];
  idade: Scalars['Int'];
  nome: Scalars['String'];
  updatedAt: CustomDate;
  whatsapp: Scalars['String'];
};

export enum PermissionEnum {
  AccountKcUserRead = 'AccountKcUserRead',
  AccountKcUserWrite = 'AccountKcUserWrite',
  AccountRead = 'AccountRead',
  AccountWrite = 'AccountWrite',
  AclRead = 'AclRead',
  CapacitacaoRead = 'CapacitacaoRead',
  CapacitacaoWrite = 'CapacitacaoWrite',
  ConfigErp = 'ConfigERP',
  CreditConfiguration = 'CreditConfiguration',
  FeatureRead = 'FeatureRead',
  LayoutRead = 'LayoutRead',
  LayoutWrite = 'LayoutWrite',
  MyMdoPayConfiguration = 'MyMdoPayConfiguration',
  NoteRead = 'NoteRead',
  NoteWrite = 'NoteWrite',
  PromoActionRead = 'PromoActionRead',
  PromoActionWrite = 'PromoActionWrite',
  PromoCampaignRead = 'PromoCampaignRead',
  PromoCampaignWrite = 'PromoCampaignWrite',
  PromoCouponRead = 'PromoCouponRead',
  PromoCouponWrite = 'PromoCouponWrite',
  PromoTriggerRead = 'PromoTriggerRead',
  PromoTriggerWrite = 'PromoTriggerWrite',
  TablePriceRead = 'TablePriceRead',
  TablePriceWrite = 'TablePriceWrite',
  UserRead = 'UserRead',
  UserSecrets = 'UserSecrets',
  UserWrite = 'UserWrite'
}

export type PermissionGroup = {
  __typename?: 'PermissionGroup';
  group: Scalars['String'];
  permissions: Array<PermissionEnum>;
};

export type PresenceReportParams = {
  account?: InputMaybe<Scalars['Int']>;
  account_group?: InputMaybe<Scalars['Int']>;
  app_name?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  app_version?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  end_date?: InputMaybe<Scalars['Date']>;
  online?: InputMaybe<PresenceReportStatusParamsEnum>;
  screen?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  search?: InputMaybe<Scalars['String']>;
  start_date?: InputMaybe<Scalars['Date']>;
};

export type PresenceReportResponse = {
  __typename?: 'PresenceReportResponse';
  offline_quantity: Scalars['Int'];
  online_quantity: Scalars['Int'];
  rows: Array<PresenceRow>;
  totals: Array<PresenceTotal>;
};

export enum PresenceReportStatusParamsEnum {
  All = 'ALL',
  Offline = 'OFFLINE',
  Online = 'ONLINE'
}

export type PresenceRow = {
  __typename?: 'PresenceRow';
  account?: Maybe<AccountInfo>;
  app_info?: Maybe<RequestAppInfo>;
  created_at: CustomDate;
  id: Scalars['ID'];
  online: Scalars['Boolean'];
  screen: Scalars['String'];
};

export type PresenceTotal = {
  __typename?: 'PresenceTotal';
  quantity: Scalars['Int'];
  screen: Scalars['String'];
};

export type PresignedFileInput = {
  mime: Scalars['String'];
  name: Scalars['String'];
};

export type Product = {
  __typename?: 'Product';
  barcode: Scalars['String'];
  code: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  price: Scalars['Float'];
  reference: Scalars['String'];
  type: ProductTypeEnum;
};

export enum ProductTypeEnum {
  Accessory = 'Accessory',
  Frame = 'Frame',
  Lens = 'Lens'
}

export type ProductV3 = {
  __typename?: 'ProductV3';
  ativo: Scalars['Boolean'];
  /** @deprecated Utilize o campo cod_auxiliares */
  cod_auxiliar?: Maybe<Scalars['String']>;
  /** Demais códigos auxiliares do produto */
  cod_auxiliares: Array<Scalars['String']>;
  /** Código de barras do produto, esse será o código principal que será utilizado no carrinho do pedido */
  cod_barra: Scalars['String'];
  /** Código do produto (uso apenas para referência interna) */
  cod_produto?: Maybe<Scalars['String']>;
  created_at: CustomDate;
  desc_colecao?: Maybe<Scalars['String']>;
  desc_linha?: Maybe<Scalars['String']>;
  desc_marca?: Maybe<Scalars['String']>;
  /** Data relacionado ao ERP */
  dt_atualizacao: CustomDate;
  /** Data relacionado ao ERP */
  dt_inclusao: CustomDate;
  em_estoque: Scalars['Boolean'];
  /** Hash de controle de sincronização */
  hash: Scalars['String'];
  id: Scalars['ID'];
  id_account: Scalars['Int'];
  id_colecao?: Maybe<Scalars['String']>;
  id_linha?: Maybe<Scalars['String']>;
  id_marca?: Maybe<Scalars['String']>;
  nome: Scalars['String'];
  portal: Scalars['String'];
  preco_venda: Scalars['Float'];
  /** Código do produto (uso apenas para referência interna) */
  referencia?: Maybe<Scalars['String']>;
  tipo: ProductV3TipoEnum;
  updated_at: CustomDate;
};

export enum ProductV3TipoEnum {
  Acessorio = 'acessorio',
  Armacao = 'armacao',
  Lente = 'lente'
}

export type ProductsEdgesResponse = {
  __typename?: 'ProductsEdgesResponse';
  edges: Array<Product>;
  pagination?: Maybe<Pagination>;
};

export type ProgressoCapacitacaoReportRow = {
  __typename?: 'ProgressoCapacitacaoReportRow';
  conta: AccountInfo;
  dt_inicio: CustomDate;
  dt_ultimo_acesso: CustomDate;
  pc_progresso: Scalars['Int'];
  perfil: PerfilCurso;
  qt_finalizado: Scalars['Int'];
  qt_gostou: Scalars['Int'];
  qt_iniciado: Scalars['Int'];
  qt_total: Scalars['Int'];
};

export type PromoAction = IPromoAction & {
  __typename?: 'PromoAction';
  description: Scalars['String'];
  discountPercent?: Maybe<Scalars['Float']>;
  discountProducts?: Maybe<Array<Scalars['String']>>;
  giftProducts?: Maybe<Array<Scalars['String']>>;
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  type: PromoActionTypeEnum;
  videoUrl?: Maybe<Scalars['String']>;
};

/** Objeto de retorno do Check result PROMO */
export type PromoActionCheck = IPromoAction & {
  __typename?: 'PromoActionCheck';
  action: Scalars['ID'];
  campaign: Scalars['ID'];
  /** Código do cupom que sera ativado na O.S. */
  coupon?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  discountPercent?: Maybe<Scalars['Float']>;
  discountProducts?: Maybe<Array<Scalars['String']>>;
  giftProducts?: Maybe<Array<Scalars['String']>>;
  imageUrl?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  type: PromoActionTypeEnum;
  videoUrl?: Maybe<Scalars['String']>;
};

export enum PromoActionTypeEnum {
  DiscountPercentMatchProduct = 'DISCOUNT_PERCENT_MATCH_PRODUCT',
  DiscountPercentTotalValue = 'DISCOUNT_PERCENT_TOTAL_VALUE',
  GiftCoupon = 'GIFT_COUPON',
  GiftProduct = 'GIFT_PRODUCT',
  Image = 'IMAGE',
  Text = 'TEXT',
  Video = 'VIDEO'
}

export type PromoCampaign = {
  __typename?: 'PromoCampaign';
  account?: Maybe<Scalars['Int']>;
  actions: Array<PromoAction>;
  campaignRefCopy?: Maybe<Scalars['ID']>;
  conditionTrigger: PromoTriggerLogicEnum;
  description: Scalars['String'];
  id: Scalars['ID'];
  limiters: PromoLimiter;
  name: Scalars['String'];
  totalUsage: Scalars['Int'];
  triggers: Array<PromoTrigger>;
  type: PromoCampaignTypeEnum;
};

export enum PromoCampaignTypeEnum {
  Account = 'ACCOUNT',
  Global = 'GLOBAL',
  Template = 'TEMPLATE'
}

export type PromoCoupon = {
  __typename?: 'PromoCoupon';
  account: Scalars['Int'];
  action: Scalars['ID'];
  campaign: Scalars['ID'];
  code: Scalars['String'];
  description: Scalars['String'];
  id: Scalars['ID'];
  order?: Maybe<Scalars['ID']>;
  usedAt?: Maybe<CustomDate>;
  validUntil: CustomDate;
};

export type PromoLimiter = {
  __typename?: 'PromoLimiter';
  endDate: Scalars['String'];
  maxUsage?: Maybe<Scalars['Int']>;
  maxUsagePerCustomer?: Maybe<Scalars['Int']>;
  startDate: Scalars['String'];
};

export type PromoTrigger = {
  __typename?: 'PromoTrigger';
  customerTimeMonths?: Maybe<Scalars['Int']>;
  description: Scalars['String'];
  id: Scalars['ID'];
  matchValue?: Maybe<Array<Scalars['String']>>;
  minValue?: Maybe<Scalars['Float']>;
  paymentMethod?: Maybe<Array<PaymentTypeEnum>>;
  paymentMinInstallment?: Maybe<Scalars['Int']>;
  purchaseCount?: Maybe<Scalars['Int']>;
  type: PromoTriggerTypeEnum;
};

export enum PromoTriggerLogicEnum {
  And = 'AND',
  Or = 'OR'
}

export enum PromoTriggerTypeEnum {
  Allow = 'ALLOW',
  CodeMatch = 'CODE_MATCH',
  CouponCode = 'COUPON_CODE',
  FirstPurchase = 'FIRST_PURCHASE',
  MinValue = 'MIN_VALUE',
  OldCustomer = 'OLD_CUSTOMER',
  PaymentMethod = 'PAYMENT_METHOD',
  PurchaseCount = 'PURCHASE_COUNT',
  TextMatch = 'TEXT_MATCH'
}

export type Query = {
  __typename?: 'Query';
  account?: Maybe<Account>;
  accountGroups: Array<AccountGroup>;
  accounts: AccountEdgesResponse;
  capacitacao?: Maybe<Capacitacao>;
  capacitacoes: Array<Capacitacao>;
  clearCache: Scalars['String'];
  countProducts: Array<CountProduct>;
  creditInterestTables: Array<CreditInterestTable>;
  creditTestConnection: TestResultResponse;
  /** @deprecated Demo purposes only */
  devGetPDF: GetPdfResponse;
  features: Array<Feature>;
  infoApi: InfoApi;
  layout?: Maybe<Layout>;
  layouts: Array<Maybe<Layout>>;
  loadCepExternalApi?: Maybe<LoadCepExternalApiResponse>;
  loadCnpjExternalApi?: Maybe<LoadCnpjExternalApiResponse>;
  mdoPayTestFromAdmin: TestResultResponse;
  myAccount: Account;
  myIp: Scalars['String'];
  myOrder: Order;
  myOrders: OrderEdgesResponse;
  myProduct: Product;
  myProducts: ProductsEdgesResponse;
  myTransaction: Transaction;
  myTransactions: TransactionEdgesResponse;
  myUser: User;
  myUserKC: UserKc;
  notesByAccount: Array<Note>;
  permissions: Array<PermissionGroup>;
  presenceReport: PresenceReportResponse;
  progressoCapacitacaoReport: Array<ProgressoCapacitacaoReportRow>;
  roles: Array<RoleEnum>;
  search: Array<SearchItem>;
  secretsByUser: Array<UserSecret>;
  tables: Array<Maybe<Table>>;
  tablesLabelGroups: Array<Maybe<Scalars['String']>>;
  testConfigErp: TestConfigErpResponse;
  user: User;
  users: UserEdgesResponse;
};


export type QueryAccountArgs = {
  id: Scalars['Int'];
};


export type QueryAccountsArgs = {
  params?: InputMaybe<SearchAccountsInput>;
};


export type QueryCapacitacaoArgs = {
  id: Scalars['ID'];
  incluirContador?: InputMaybe<Scalars['Boolean']>;
};


export type QueryCapacitacoesArgs = {
  params?: InputMaybe<SearchCapacitacoesInput>;
};


export type QueryCountProductsArgs = {
  account: Scalars['Int'];
};


export type QueryCreditTestConnectionArgs = {
  input: UpsertCreditConfigsInput;
};


export type QueryLayoutArgs = {
  ref: Scalars['String'];
};


export type QueryLoadCepExternalApiArgs = {
  cep: Scalars['String'];
};


export type QueryLoadCnpjExternalApiArgs = {
  cnpj: Scalars['String'];
};


export type QueryMdoPayTestFromAdminArgs = {
  account: Scalars['Int'];
};


export type QueryMyOrderArgs = {
  id: Scalars['ID'];
};


export type QueryMyOrdersArgs = {
  params?: InputMaybe<SearchOrdersInput>;
};


export type QueryMyProductArgs = {
  code: Scalars['String'];
};


export type QueryMyProductsArgs = {
  params?: InputMaybe<SearchProductsInput>;
};


export type QueryMyTransactionArgs = {
  id: Scalars['ID'];
};


export type QueryMyTransactionsArgs = {
  params?: InputMaybe<SearchTransactionsInput>;
};


export type QueryNotesByAccountArgs = {
  account: Scalars['Int'];
};


export type QueryPresenceReportArgs = {
  params?: InputMaybe<PresenceReportParams>;
};


export type QueryProgressoCapacitacaoReportArgs = {
  capacitacao: Scalars['ID'];
};


export type QuerySearchArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  term: Scalars['String'];
  type?: InputMaybe<SearchItemTypeEnum>;
};


export type QuerySecretsByUserArgs = {
  id: Scalars['Int'];
};


export type QueryTablesArgs = {
  params: SearchTablesInput;
};


export type QueryTestConfigErpArgs = {
  config: ConfigErpInput;
};


export type QueryUserArgs = {
  id: Scalars['Int'];
};


export type QueryUsersArgs = {
  params?: InputMaybe<SearchUsersInput>;
};

export enum ReportGroupDateEnum {
  DayOfMonth = 'DayOfMonth',
  Month = 'Month',
  Week = 'Week',
  Year = 'Year'
}

export type RequestAppInfo = {
  __typename?: 'RequestAppInfo';
  ip?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  platform?: Maybe<Scalars['String']>;
  serial?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
};

export type RequestCancelPaymentInput = {
  cancel_reason?: InputMaybe<Scalars['String']>;
  nsu_rede: Scalars['String'];
  ref_sdk_id?: InputMaybe<Scalars['Int']>;
};

export enum RoleEnum {
  Admin = 'Admin',
  Franqueado = 'Franqueado',
  PromoAdmin = 'PromoAdmin',
  Seller = 'Seller',
  SupportSaaS = 'SupportSaaS',
  User = 'User'
}

export type SearchAccountsInput = {
  and?: InputMaybe<ConditionAccountInput>;
  page?: InputMaybe<Scalars['Int']>;
  per_page?: InputMaybe<Scalars['Int']>;
  q?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<SortAccountInput>;
};

export type SearchCapacitacoesInput = {
  app?: InputMaybe<CapacitacaoAppEnum>;
};

export type SearchItem = {
  __typename?: 'SearchItem';
  account_id?: Maybe<Scalars['Int']>;
  account_name?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  type: SearchItemTypeEnum;
};

export enum SearchItemTypeEnum {
  Account = 'Account',
  GroupAccount = 'GroupAccount',
  User = 'User'
}

export type SearchOrdersInput = {
  and?: InputMaybe<ConditionOrderInput>;
  page?: InputMaybe<Scalars['Int']>;
  per_page?: InputMaybe<Scalars['Int']>;
  q?: InputMaybe<Scalars['String']>;
};

export type SearchProductsInput = {
  and?: InputMaybe<ConditionProductInput>;
  page?: InputMaybe<Scalars['Int']>;
  per_page?: InputMaybe<Scalars['Int']>;
  q?: InputMaybe<Scalars['String']>;
};

export type SearchTablesInput = {
  account?: InputMaybe<Scalars['Int']>;
  account_find_labels?: InputMaybe<Scalars['Boolean']>;
  labels?: InputMaybe<Array<Scalars['String']>>;
};

export type SearchTransactionsInput = {
  and?: InputMaybe<ConditionTransactionInput>;
  page?: InputMaybe<Scalars['Int']>;
  per_page?: InputMaybe<Scalars['Int']>;
  q?: InputMaybe<Scalars['String']>;
};

export type SearchUsersInput = {
  and?: InputMaybe<ConditionUserInput>;
  page?: InputMaybe<Scalars['Int']>;
  per_page?: InputMaybe<Scalars['Int']>;
  q?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<SortUserInput>;
};

export type SimpleResponse = {
  __typename?: 'SimpleResponse';
  message: Scalars['String'];
};

export type SortAccountInput = {
  account?: InputMaybe<SortOrderEnum>;
  created_at?: InputMaybe<SortOrderEnum>;
};

export enum SortOrderEnum {
  Asc = 'Asc',
  Desc = 'Desc'
}

export type SortUserInput = {
  created?: InputMaybe<SortOrderEnum>;
  login?: InputMaybe<SortOrderEnum>;
};

export type Subscription = {
  __typename?: 'Subscription';
  onNewMyOrder: Order;
  pong: SimpleResponse;
};


export type SubscriptionPongArgs = {
  minLength?: InputMaybe<Scalars['Int']>;
};

export type Table = {
  __typename?: 'Table';
  copy_ref?: Maybe<Scalars['Int']>;
  created_at: CustomDate;
  description: Scalars['String'];
  filename: Scalars['String'];
  id: Scalars['Int'];
  is_owner: Scalars['Boolean'];
  labels?: Maybe<Array<Scalars['String']>>;
  link: Scalars['String'];
  origin?: Maybe<Scalars['String']>;
  original_filename: Scalars['String'];
  path?: Maybe<Scalars['String']>;
  product_type: TableProdTypeEnum;
  table_id: Scalars['Int'];
  table_type: Scalars['String'];
  timestamp: Scalars['Float'];
  updated_at: CustomDate;
};

export type TablePriceErp = {
  __typename?: 'TablePriceErp';
  label: Scalars['String'];
  value: Scalars['String'];
};

export enum TableProdTypeEnum {
  Bifocal = 'Bifocal',
  Multifocal = 'Multifocal',
  Ocupacional = 'Ocupacional',
  Simples = 'Simples'
}

export type TableResponse = {
  __typename?: 'TableResponse';
  data: Array<Table>;
  message: Scalars['String'];
};

export type TableUpload = {
  description: Scalars['String'];
  file: Scalars['Upload'];
  type: TableProdTypeEnum;
};

export type TestConfigErpResponse = {
  __typename?: 'TestConfigErpResponse';
  error_code?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  status: Scalars['Boolean'];
};

export type TestResult = {
  __typename?: 'TestResult';
  code: Scalars['String'];
  description: Scalars['String'];
  icon: AlertIconEnum;
  title: Scalars['String'];
};

export type TestResultResponse = {
  __typename?: 'TestResultResponse';
  message: Scalars['String'];
  result: Array<TestResult>;
};

export type Topico = {
  __typename?: 'Topico';
  createdAt: CustomDate;
  id: Scalars['ID'];
  ordem: Scalars['Int'];
  status: CapacitacaoStatus;
  tags: Array<Scalars['String']>;
  titulo: Scalars['String'];
  updatedAt: CustomDate;
  videos: Array<Video>;
};

export type Transaction = {
  __typename?: 'Transaction';
  /**  Informações do estabelecimento  */
  account: AccountInfo;
  /**  Valor total da transação  */
  amount: Scalars['Float'];
  /**  Informações do aplicativo que está realizando a transação  */
  app_info: RequestAppInfo;
  /**  Carrinho de compras  */
  carts: Array<TransactionCart>;
  /**
   * Informações do cliente
   * Obs: Na venda direta não sera exibido
   */
  client?: Maybe<TransactionClient>;
  created_at: CustomDate;
  /**  Valor do desconto da transação  */
  discount: Scalars['Float'];
  erp?: Maybe<TransactionErp>;
  /**  Tipo do fluxo de pagamento  */
  flow: TransactionFlowEnum;
  id: Scalars['ID'];
  /**
   * Número de parcelas da transação
   * 0: débito
   * 1: para pagamento à vista
   * *: parcelado
   */
  max_installments: Scalars['Int'];
  /**
   * Pedido Visiolens
   * Obs: Sera exibido apenas quando o fluxo for QrCode ou OrderList
   */
  order?: Maybe<Order>;
  /**  Lista dos pagamentos  */
  payments: Array<Payment>;
  /**  Status da transação  */
  status: TransactionStatusEnum;
  /**  Resumo dos valores da transação  */
  summary?: Maybe<TransactionSummary>;
  updated_at: CustomDate;
  /**  Informações do usuário que realizou a transação  */
  user?: Maybe<UserInfo>;
};

export type TransactionCart = {
  __typename?: 'TransactionCart';
  /**  Valor do produto sem TAXA  */
  base_unit_price?: Maybe<Scalars['Float']>;
  description: Scalars['String'];
  product_code: Scalars['String'];
  quantity: Scalars['Int'];
  total: Scalars['Float'];
  unit_price: Scalars['Float'];
};

export type TransactionCartInput = {
  /**  Valor do produto sem TAXA  */
  base_unit_price?: InputMaybe<Scalars['Float']>;
  /**  Nome do produto  */
  description: Scalars['String'];
  /**  Código de barras do produto  */
  product_code: Scalars['String'];
  /**  Quantidade do produto  */
  quantity: Scalars['Int'];
  /**  Preço unitário do produto  */
  unit_price: Scalars['Float'];
};

export type TransactionClient = {
  __typename?: 'TransactionClient';
  /**  CPF do cliente  */
  document: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type TransactionClientInput = {
  /**  CPF do cliente  */
  document: Scalars['String'];
  /**  Nome do cliente  */
  name?: InputMaybe<Scalars['String']>;
  /**  Telefone do cliente  */
  phone?: InputMaybe<Scalars['String']>;
};

export type TransactionEdgesResponse = {
  __typename?: 'TransactionEdgesResponse';
  edges: Array<Transaction>;
};

export type TransactionErp = {
  __typename?: 'TransactionErp';
  homolog?: Maybe<Scalars['Boolean']>;
  nfc_bmp?: Maybe<Scalars['String']>;
  nfc_number?: Maybe<Scalars['String']>;
  nfc_serie?: Maybe<Scalars['String']>;
  venda_id?: Maybe<Scalars['String']>;
};

export enum TransactionFlowEnum {
  /**  Fluxo de pagamento a partir da lista de pedido Visiolens  */
  Dav = 'DAV',
  /**  Venda direta, na qual é informada apenas o valor a ser pago  */
  DirectSale = 'DirectSale',
  /**  Fluxo de pagamento utilizando o Scan BarCode para montar o carrinho no próprio SmartPOS  */
  ExpressSale = 'ExpressSale',
  /**  Fluxo de pagamento a partir da lista de pedido Visiolens  */
  OrderList = 'OrderList',
  /**  Fluxo de pagamento a partir de um QrCode de pedido Visiolens  */
  QrCode = 'QrCode'
}

export type TransactionResponse = {
  __typename?: 'TransactionResponse';
  data: Transaction;
  message: Scalars['String'];
};

export enum TransactionStatusEnum {
  /**  Transação cancelada  */
  Cancelled = 'Cancelled',
  /**  Transação finalizada com todos pagamentos aprovados  */
  Completed = 'Completed',
  /**  Transação criada, mas ainda não foi iniciada  */
  Created = 'Created',
  /**  Transação iniciada, mas ainda não foi finalizada  */
  Processing = 'Processing'
}

export type TransactionSummary = {
  __typename?: 'TransactionSummary';
  /**  Representa o valor já pago  */
  amount_paid: Scalars['Float'];
  /**  Representa o desconto aplicado ao pedido  */
  discount: Scalars['Float'];
  /**  Representa o valor total após aplicar o desconto  */
  discounted_amount: Scalars['Float'];
  /**  Representa o valor restante a ser pago  */
  remaining_amount: Scalars['Float'];
  /**  Representa o valor total dos produtos no pedido  */
  total_cart: Scalars['Float'];
};

export type UpdateAccountDetailInput = {
  address?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  cnpj?: InputMaybe<Scalars['String']>;
  company_name?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  cpf_rep?: InputMaybe<Scalars['String']>;
  district?: InputMaybe<Scalars['String']>;
  email_adm?: InputMaybe<Scalars['String']>;
  email_rep?: InputMaybe<Scalars['String']>;
  ie?: InputMaybe<Scalars['String']>;
  name_rep?: InputMaybe<Scalars['String']>;
  phone_number1?: InputMaybe<Scalars['String']>;
  phone_number2?: InputMaybe<Scalars['String']>;
  razao_social?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  zip_code?: InputMaybe<Scalars['String']>;
};

export type UpdateAccountInput = {
  account?: InputMaybe<Scalars['String']>;
  detail?: InputMaybe<UpdateAccountDetailInput>;
  feature_id?: InputMaybe<Scalars['Int']>;
  group_id?: InputMaybe<Scalars['Int']>;
  meta_configs?: InputMaybe<Scalars['JSON']>;
  meta_labels?: InputMaybe<Scalars['JSON']>;
  password_manager?: InputMaybe<Scalars['String']>;
};

export type UpdateCapacitacaoInput = {
  app?: InputMaybe<CapacitacaoAppEnum>;
  ativo?: InputMaybe<Scalars['Boolean']>;
  descricao?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  titulo?: InputMaybe<Scalars['String']>;
};

export type UpdateTransactionInput = {
  /**  Valor total da transação  */
  amount?: InputMaybe<Scalars['Float']>;
  carts?: InputMaybe<Array<TransactionCartInput>>;
  /**  Informações do cliente  */
  client?: InputMaybe<TransactionClientInput>;
  /**  (R$) Valor do desconto da transação  */
  discount?: InputMaybe<Scalars['Float']>;
  /**  Tipo do fluxo de pagamento  */
  flow: TransactionFlowEnum;
  /**
   * Número de parcelas da transação
   *
   * Use o mesmo numero de parcelas simulado no Visiolnes!
   *
   * - 0 para debito
   * - 1 para pagamento à vista
   * - 2+ para parcelado
   */
  max_installments?: InputMaybe<Scalars['Int']>;
};

export type UpdateUserInput = {
  account?: InputMaybe<Scalars['Int']>;
  login?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<RoleEnum>;
};

export type UploadSignedFields = {
  __typename?: 'UploadSignedFields';
  key: Scalars['String'];
  value: Scalars['String'];
};

export type UploadSignedObject = {
  __typename?: 'UploadSignedObject';
  fields: Array<UploadSignedFields>;
  fullUrl: Scalars['String'];
  key: Scalars['String'];
  url: Scalars['String'];
};

export type UpsertAccountKcUserInput = {
  account: Scalars['Int'];
  kc_sub: Scalars['String'];
  /** Passe array vazio para remover relação */
  roles: Array<RoleEnum>;
};

export type UpsertCreditConfigsInput = {
  account: Scalars['Int'];
  auth_provider?: InputMaybe<CreditAuthProviderInput>;
  provider: CreditMdoProviderEnum;
  table?: InputMaybe<Scalars['String']>;
};

export type UpsertCreditConfigsResponse = {
  __typename?: 'UpsertCreditConfigsResponse';
  data: CreditConfigs;
  message: Scalars['String'];
};

export type UpsertMdoPayConfigsInput = {
  flows?: InputMaybe<MdoPayFlowConfigInput>;
  gateway?: InputMaybe<MdoPayGatewayInput>;
  limits?: InputMaybe<MdoPayLimitsInput>;
  /** Senha administrativa para aplicar atualização das configurações */
  password: Scalars['String'];
};

export type UpsertMdoPayConfigsResponse = {
  __typename?: 'UpsertMdoPayConfigsResponse';
  data: MdoPayConfigs;
  message: Scalars['String'];
};

export type UpsertTopicoCapacitacaoInput = {
  capacitacao: Scalars['ID'];
  ordem: Scalars['Int'];
  tags: Array<Scalars['String']>;
  titulo: Scalars['String'];
  topico?: InputMaybe<Scalars['ID']>;
};

export type UpsertVideoCapacitacaoInput = {
  capacitacao: Scalars['ID'];
  link_video: Scalars['String'];
  ordem: Scalars['Int'];
  titulo: Scalars['String'];
  topico: Scalars['ID'];
  video?: InputMaybe<Scalars['ID']>;
};

export type User = {
  __typename?: 'User';
  account?: Maybe<AccountInfo>;
  created_at: CustomDate;
  deleted_at?: Maybe<CustomDate>;
  id: Scalars['Int'];
  last_login?: Maybe<CustomDate>;
  login: Scalars['String'];
  permissions: Array<PermissionEnum>;
  role: RoleEnum;
  updated_at: CustomDate;
};

export type UserEdgesResponse = {
  __typename?: 'UserEdgesResponse';
  edges: Array<User>;
  pagination?: Maybe<Pagination>;
};

export type UserInfo = {
  __typename?: 'UserInfo';
  id: Scalars['Int'];
  login: Scalars['String'];
};

export type UserKc = {
  __typename?: 'UserKC';
  email: Scalars['String'];
  name: Scalars['String'];
  permissions: Array<PermissionEnum>;
  role: RoleEnum;
  sub: Scalars['String'];
  username: Scalars['String'];
};

export type UserResponse = {
  __typename?: 'UserResponse';
  data: User;
  message: Scalars['String'];
};

export type UserSecret = {
  __typename?: 'UserSecret';
  login: Scalars['String'];
  name: Scalars['String'];
  password: Scalars['String'];
};

export type Video = {
  __typename?: 'Video';
  contador?: Maybe<VideoContador>;
  createdAt: CustomDate;
  finalizado: Scalars['Boolean'];
  gostei: Scalars['Boolean'];
  id: Scalars['ID'];
  iniciado: Scalars['Boolean'];
  link_video: Scalars['String'];
  ordem: Scalars['Int'];
  titulo: Scalars['String'];
  updatedAt: CustomDate;
};

export type VideoContador = {
  __typename?: 'VideoContador';
  finalizados: Scalars['Int'];
  gostaram: Scalars['Int'];
  iniciados: Scalars['Int'];
};
