import meta from '../../package.json';
import { Environment } from './env.type';

export const baseEnvironment: Environment = {
    envName: 'base',
    graphqlUrl: 'https://api-v2.visiolens.net.br/graphql',
    clientName: 'Painel MDO ',
    clientVersion: meta.version,
    clientPlatform: 'Web',
    language: 'pt-br',
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
};
